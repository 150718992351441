<template>
<div style="height:100%;width:100%">
    <div  class="zoomImageL">
            <img class="logoImg" src="../static/logo.png"/>
            <div class="logFontDiv">
                <p>Sales forecasts drive performance</p>
                <p>销量预测驱动业绩提升</p>
            </div>
    </div> 
    <div  class="zoomImageR">
    </div> 
    <el-card class="box-card log" >
        <h3 style="text-align:center;margin-bottom:35px">销量预测系统</h3>
        <el-form label-position="left"  :model="ruleForm"  label-width="0px"  :rules="rules"  ref="ruleForm" name="ruleForm">
            <el-form-item label=""  prop="username">
                <el-input v-model="ruleForm.username" class="lginInputStyle" placeholder="用户名"  suffix-icon="el-icon-user"></el-input>
            </el-form-item>
            <el-form-item label=""  prop="password">
                <el-input v-model="ruleForm.password" type="password" @keyup.enter.native="loginClick" class="lginInputStyle" placeholder="密码"  suffix-icon="el-icon-lock"></el-input>
            </el-form-item>
            <el-form-item>
                <span style="color:#b20d5e;font-size: 12px;">*忘记密码请联系管理员</span>
            </el-form-item>
            <el-form-item>
                <span></span>
            </el-form-item>
             <el-form-item>
                <span></span>
            </el-form-item>
            <el-form-item>
                <el-button class="btn-close" @click="loginClick" style="width:100%">登录</el-button>
            </el-form-item>
        </el-form>
        
    </el-card>
</div>

    
</template>
<script>

export default {
    name:"login",
    data(){
        return {
            ruleForm:{
                username:"",
                password:"",
            },
            rules:{
                username:[
                     { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                password:[
                     { required: true, message: '请输入密码', trigger: 'blur' }
                ]
            }
        }
    },
    methods:{
        loginClick(){
             this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    let param=this.ruleForm
                    this.Api.request("/user/login","post",param,this.loginSuccess,this.loginFailure,"python")
                }
            });
        },
        loginSuccess(ret){
            this.Util.setStorage("permission_list",ret.permission_list)
            this.Util.setStorage("userId",ret.user_data.id)
            this.Util.setStorage("username",ret.user_data.username)
            this.Util.setStorage("isAdmin",ret.user_data.isadmin)
            if(ret.user_data.isadmin==1){
                 window.location.href = '/dataInputInfo'
            }else{
                window.location.href = '/marketCapacity'
            }
            
        },
        loginFailure(ret){
            this.$message({
                message: ret.msg==""||ret.msg==undefined?'登录失败！':ret.msg,
                type: 'warning'
            })
        }
    }
}
</script>
<style scoped>
.log{
    width: 400px;
    z-index: 100;
    position: absolute;
    top: 200px;
    left: 50%;
    margin-left: -200px;
    border-radius: 30px;
}
.logDiv{
    margin-top: 200px;
    background: #245e9a;
    height: 290px;
    color: #fff;
    line-height: 290px;
    font-size: 30px;
    padding-left: 20px;
    min-width:280px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: 3px 3px 3px #dad8d8;
}
.lginInputStyle{
    border-top: 0;
    border-left: 0;
    border-right: 0;
} 
.zoomImageL{
    height:100%;
    width:50%;
    float:left;
    background-image:url(../static/bg1.png);
    background-repeat:no-repeat;
    background-size:100% 100%;
    -moz-background-size:100% 100%;
    }
.zoomImageR{
    height:100%;
    width:50%;
    float:right;
     /* background-image:url(../static/bg4.png); */
    background-repeat:no-repeat;
    background-size:100% 100%;
    -moz-background-size:100% 100%;
    }
.logoImg{
    margin-top: 20px;
    margin-left: 20px;
}
.logFontDiv{
    color: #fff;
    font-size: 24px;
    margin-left: 20px;
    margin-top: 230px;
}


</style>